<script lang="ts" setup>
import { ref, computed, watch, onMounted, getCurrentInstance } from "vue";
import { getPartyDetails, patchPartyDetails, postPartyDetails, getPartyTypes, getSourceTypes, deleteLogo } from "@/services/api/party.api";
import { ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import _ from "lodash";
import { emptyGuid } from "@/composables/miscellaneous.js";
import { v4 as uuidv4 } from "uuid";
import router from "@/router/index";
import useDetailEdit from "@/composables/detailEdit.js";
import { useCacheStore } from "@/stores/cache";
import DateInput from "@/components/general/DateInput.vue";
import UpdatePartyModal from "@/components/party/UpdatePartyModal.vue";
import ContactRoleSelection from "@/components/party/ContactRoleSelection.vue";

const {
  setMode,
  setReadonlyMode,
  setEditMode,
  setEditTLMode,
  setDetails,
  hasDetails,
  selectNewIndex,
  selectedData,
  updateSelectedDetail,
  resetSelectedDetail,
  isReadOnly,
  isAddingMode,
  isAddingMasterMode,
  isAddingDetailMode,
  isEditTLMode,
  allowEdit,
  allowEditTL,
  addNewDetailMode,
  detailTabs,
  modDateFormatted,
  checkDateValid,
} = useDetailEdit();

setInteractionMode("eager");

const props = defineProps({
  id: String,
  partyReference: String,
  addModus: {
    type: Boolean,
    default: false,
  },
});

const partyRef = ref("");
const dataMustBeRetrieved = ref(false);

const parentParties = ref([]);
const parentPartiesLoaded = ref(false);
const partyTypes = ref([]);
const sourceTypes = ref([]);
const partyTypesLoaded = ref(false);
const sourceTypesLoaded = ref(false);
const isSaving = ref(false);

const selectedTabIndex = ref(undefined);

const cacheStore = useCacheStore();

const masterDataLoaded = computed(() => parentPartiesLoaded.value && partyTypesLoaded.value);
const logoName = computed(() => {
  return (process.env.VUE_APP_PORTAL_URL ? process.env.VUE_APP_PORTAL_URL : "/") + "api/asset/logo/" + partyRef.value;
});
const contactList = computed(() => {
  if (selectedData.value.contacts) {
    const list = [...selectedData.value.contacts];
    list.sort((a, b) => a.partyRoleDescription.localeCompare(b.partyRoleDescription));
    return list;
  } else {
    return [];
  }
});

watch(
  () => [props.partyReference, props.addModus],
  () => {
    setMode(props.partyReference, props.addModus);
    dataMustBeRetrieved.value = true;
    partyRef.value = props.partyReference;
    fetchData();
  },
  { immediate: true }
);

watch(
  () => masterDataLoaded.value,
  () => {
    if (!isAddingMode.value) {
      fetchData();
    } else {
      selectedData.value = { contacts: [] };
    }
  }
);

watch(
  () => selectedTabIndex.value,
  () => {
    selectNewIndex(selectedTabIndex.value);
  }
);

onMounted(() => {
  loadParentParties();
  loadPartyTypes();
  loadSourceTypes();
});

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  if (dataMustBeRetrieved.value && masterDataLoaded.value) {
    const response = await getPartyDetails(partyRef.value);

    if (response && !response.error) {
      if (response.data.length > 0) {
        //geef all contacten een unique id
        response.data.forEach((element) => {
          if (element.contacts && element.contacts.length > 0) {
            element.contacts.forEach((contact) => {
              contact.keyId = uuidv4();
            });
          }
        });
        selectedTabIndex.value = setDetails(response.data);
      }
    } else {
      proxy.$toaster.error("Ophalen van de data is mislukt! " + response.error);
    }
    dataMustBeRetrieved.value = false;
  }
}

const obs = ref(null);

async function saveDetail() {
  isSaving.value = true;
  const valid = await obs.value.validate();
  if (valid) {
    let data = {};
    if (isEditTLMode.value) {
      data = { valFrom: selectedData.value.valFrom, valTill: selectedData.value.valTill, modDescr: selectedData.value.modDescr };
    } else {
      data = JSON.parse(JSON.stringify(selectedData.value));
      //datums mogen niet geupdate worden
      data.valFrom = null;
      data.valTill = null;
    }
    const result = await patchPartyDetails(selectedData.value.detailId, data);
    if (!result.error) {
      updateSelectedDetail();
      if (isEditTLMode.value) {
        // refresh de data i.v.m. gewijzigde tijdlijn.
        fetchData();
      }
      setReadonlyMode();
      proxy.$toaster.success("Gegevens van deze partij zijn successvol gewijzigd.");
    } else {
      proxy.$toaster.error(result.error);
    }
  }
  isSaving.value = false;
}

function addNewDetail() {
  if (selectedData.value) {
    addNewDetailMode();
  } else {
    proxy.$toaster.warning("Selecteer eerst een versie s.v.p..");
  }
}

async function saveNewDetail() {
  isSaving.value = true;
  const valid = await obs.value.validate();
  if (valid) {
    let data = {};
    data = JSON.parse(JSON.stringify(selectedData.value));
    const result = await postPartyDetails(data);
    if (!result.error) {
      partyRef.value = result.data.ref.toString();
      dataMustBeRetrieved.value = true;
      fetchData();
      setReadonlyMode();
      proxy.$toaster.success("Gegevens van deze partij zijn successvol toegevoegd.");
    } else {
      proxy.$toaster.error(result.error);
    }
  }
  isSaving.value = false;
}

async function onCancelAdd() {
  if (isAddingMasterMode.value) {
    // Nieuwe party
    proxy.$toaster.warning(`Party toevoegen geannuleerd.`);
    router.push({ name: "Parties" });
  } else {
    proxy.$toaster.warning(`Partyversie toevoegen geannuleerd.`);
    setReadonlyMode();
    resetSelectedDetail();
  }
}

async function onCancel() {
  proxy.$toaster.warning(`bewerken geannuleerd.`);
  selectNewIndex(selectedTabIndex.value);
  requestAnimationFrame(() => {
    obs.value.reset();
  });
  setReadonlyMode();
}

async function loadParentParties() {
  //console.log("PartyDetails, loadParentParties ");
  const response = await cacheStore.parties();
  if (response.success) {
    parentParties.value = _.sortBy([...response.list], "name");
    parentParties.value.unshift({ id: emptyGuid, name: "Geen" });
    parentPartiesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de parent parties is mislukt! " + response.error);
  }
}

async function loadPartyTypes() {
  const response = await getPartyTypes();
  if (response && !response.error) {
    partyTypes.value = response.data.keyDescList;
    partyTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de party types is mislukt! " + response.error);
  }
}

async function loadSourceTypes() {
  const response = await getSourceTypes();
  if (response && !response.error) {
    sourceTypes.value = response.data.keyDescList;
    sourceTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de party source types is mislukt! " + response.error);
  }
}

async function onDeleteLogo() {
  if (confirm("Weet u zeker dat u dit logo wilt verwijderen?")) {
    // console.log("PartyDetails, delete logo ");
    await deleteLogo(partyRef.value);
    // console.log("PartyDetails, logo verwijderd");
    location.reload(true);
  }
}

function onAddContact(contact) {
  // console.log("PartyDetails, onAddContact ", contact);
  selectedData.value.contacts.push({
    keyId: uuidv4(),
    id: emptyGuid,
    partyRoleKey: contact.roleKey,
    partyRoleDescription: contact.roleDescription,
    userId: contact.userId,
    userDescription: contact.userDescription,
  });
  // console.log("PartyDetails, onAddContact ", selectedData.value.contacts);
}

function delContact(keyId) {
  const index = selectedData.value.contacts.findIndex((item) => {
    return item.keyId == keyId;
  });
  selectedData.value.contacts.splice(index, 1);
}
</script>

<template>
  <v-container fluid pa-0>
    <header class="app-welcome-message">
      <h2 v-if="!isAddingMasterMode && !isAddingDetailMode">Details van partij</h2>
      <h2 v-else-if="isAddingMasterMode">Toevoegen van partij</h2>
      <h2 v-else-if="isAddingDetailMode">Toevoegen van partijversie</h2>
    </header>
    <v-row>
      <v-col>
        <v-container fluid>
          <v-row align="center">
            <v-col v-if="hasDetails" cols="auto"> Tijdlijn: </v-col>
            <v-col>
              <v-btn-toggle v-model="selectedTabIndex" group mandatory color="blue accent-3" elevation="2">
                <v-btn class="v-application elevation-2" v-for="(item, index) in detailTabs()" :key="index" :value="index" :disabled="!isReadOnly"> {{ item }} </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-container>
        <ValidationObserver ref="obs">
          <v-form>
            <masonry :cols="{ default: 2, 1264: 1 }" :gutter="12">
              <v-container class="boxed" fluid :class="{ noBorders: !isEditTLMode }">
                <header>Geldigheid</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Geldig vanaf" ref="validFrom" rules="date_between|required" v-slot="{ errors }" :mode="checkDateValid">
                      <date-input
                        v-model="selectedData.valFrom"
                        persistent-placeholder
                        label="Geldig vanaf"
                        :errors="errors"
                        :class="{ noBorders: !allowEditTL }"
                        :outlined="allowEditTL"
                        :readonly="!allowEditTL"
                        rules="required"
                      ></date-input>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Geldig tot" :rules="`date_between|date_after:${selectedData.valFrom}`" v-slot="{ errors }" :mode="checkDateValid">
                      <date-input
                        v-model="selectedData.valTill"
                        persistent-placeholder
                        label="Geldig tot"
                        :errors="errors"
                        :class="{ noBorders: !allowEditTL }"
                        :outlined="allowEditTL"
                        :readonly="!allowEditTL"
                      ></date-input>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="boxed" :class="{ noBorders: !isAddingMasterMode && !isAddingDetailMode }">
                <header>Aanpassing</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Aanpassings omschrijving" rules="required" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        persistent-placeholder
                        label="Omschrijving"
                        v-model="selectedData.modDescr"
                        :error-messages="errors"
                        :class="{ noBorders: !allowEdit && !allowEditTL }"
                        :outlined="allowEdit || allowEditTL"
                        :readonly="!allowEdit && !allowEditTL"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row v-if="!isAddingMode">
                  <v-col>
                    <v-text-field hide-details="auto" label="Door" persistent-placeholder v-model="selectedData.modBy" readonly class="noBorders"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="!isAddingMode">
                  <v-col>
                    <v-text-field hide-details="auto" label="Datum" persistent-placeholder v-model="modDateFormatted" readonly class="noBorders"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </masonry>
            <masonry :cols="{ default: 3, 768: 2, 1264: 1 }" :gutter="12">
              <v-container class="boxed">
                <header>Logo</header>
                <v-row>
                  <v-col>
                    <img :src="logoName" style="max-width: 200px; max-height: 200px" />
                  </v-col>
                  <v-col>
                    <v-container>
                      <v-row>
                        <v-col>
                          <update-party-modal :partyReference="partyRef"></update-party-modal>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-btn dark class="secondary" title="Verwijderen" v-on:click="onDeleteLogo()">
                            <v-icon dark left>mdi-delete</v-icon>
                            Verwijderen
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="boxed">
                <header>Energieprijzen</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Prijs elektriciteit" rules="decimal:4|between:0,999999999.9999" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        step="0.01"
                        hide-details="auto"
                        label="Prijs elektriciteit"
                        persistent-placeholder
                        :value="selectedData.priceElec"
                        @input="selectedData.priceElec = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">€/kWh</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Prijs gas" rules="decimal:4|between:0,999999999.9999" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        step="0.01"
                        hide-details="auto"
                        label="Prijs gas"
                        persistent-placeholder
                        :value="selectedData.priceGas"
                        @input="selectedData.priceGas = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">€/m³</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Prijs biomassa" rules="decimal:4|between:0,999999999.9999" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        step="0.01"
                        hide-details="auto"
                        label="Prijs biomassa"
                        persistent-placeholder
                        :value="selectedData.priceBio"
                        @input="selectedData.priceBio = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">€/m³</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Prijs warmte" rules="decimal:4|between:0,999999999.9999" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        step="0.01"
                        hide-details="auto"
                        label="Prijs warmte"
                        persistent-placeholder
                        :value="selectedData.priceHeat"
                        @input="selectedData.priceHeat = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">€/GJ</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Prijs koude" rules="decimal:4|between:0,999999999.9999" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        step="0.01"
                        hide-details="auto"
                        label="Prijs koude"
                        persistent-placeholder
                        :value="selectedData.priceCold"
                        @input="selectedData.priceCold = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">€/GJ</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="boxed">
                <header>Verduurzamingsradar</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Score strategie" rules="decimal:4|between:0,7" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        step="0.1"
                        hide-details="auto"
                        label="Score strategie"
                        persistent-placeholder
                        :value="selectedData.radarStrategy"
                        @input="selectedData.radarStrategy = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Score inzicht" rules="decimal:4|between:0,7" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        step="0.1"
                        hide-details="auto"
                        label="Score inzicht"
                        persistent-placeholder
                        :value="selectedData.radarInsight"
                        @input="selectedData.radarInsight = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Score gedeelde waarden" rules="decimal:4|between:0,7" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        step="0.1"
                        hide-details="auto"
                        label="Score gedeelde waarden"
                        persistent-placeholder
                        :value="selectedData.radarSharedValues"
                        @input="selectedData.radarSharedValues = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Score status" rules="decimal:4|between:0,7" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        step="0.1"
                        hide-details="auto"
                        label="Score status"
                        persistent-placeholder
                        :value="selectedData.radarStatus"
                        @input="selectedData.radarStatus = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Score slagkracht" rules="decimal:4|between:0,7" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        step="0.1"
                        hide-details="auto"
                        label="Score slagkracht"
                        persistent-placeholder
                        :value="selectedData.radarStrikingPower"
                        @input="selectedData.radarStrikingPower = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
              <v-container class="boxed">
                <header>Algemene gegevens</header>
                <v-row v-if="selectedData.reference != 0">
                  <v-col>
                    <v-text-field hide-details="auto" label="Referentie" v-model="selectedData.reference" readonly class="noBorders"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="selectedData.reference != 0">
                  <v-col>
                    <v-select
                      hide-details="auto"
                      label="Parent"
                      persistent-placeholder
                      :items="parentParties"
                      item-text="name"
                      item-value="id"
                      v-model="selectedData.parId"
                      :outlined="allowEdit"
                      :class="{ noBorders: !allowEdit }"
                      :readonly="!allowEdit"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Naam" rules="required|max:250" v-slot="{ errors, valid }">
                      <v-text-field
                        hide-details="auto"
                        label="Naam"
                        persistent-placeholder
                        :counter="250"
                        v-model="selectedData.name"
                        :error-messages="errors"
                        :success="valid"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Omschrijving" rules="required|max:1000" v-slot="{ errors, valid }">
                      <v-text-field
                        hide-details="auto"
                        label="Omschrijving"
                        persistent-placeholder
                        :counter="1000"
                        v-model="selectedData.descr"
                        :error-messages="errors"
                        :success="valid"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      hide-details="auto"
                      label="Type"
                      persistent-placeholder
                      :items="partyTypes"
                      item-text="descr"
                      item-value="key"
                      v-model="selectedData.type"
                      :outlined="allowEdit"
                      :class="{ noBorders: !allowEdit }"
                      :readonly="!allowEdit"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
              <v-container class="boxed">
                <header>Contacten</header>

                <v-row v-for="item in contactList" v-bind:key="item.keyId">
                  <v-col cols="4">{{ item.partyRoleDescription }}</v-col>
                  <v-col>{{ item.userDescription }}</v-col>
                  <v-col v-if="item.valid==false"> (niet actief) </v-col>
                  <v-col cols="auto">
                    <v-btn v-if="allowEdit" @click="delContact(item.keyId)" icon small plain>
                      <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider v-if="allowEdit" class="my-3"></v-divider>
                <contact-role-selection v-if="allowEdit" @on-add-contact="onAddContact"></contact-role-selection>
              </v-container>
              <v-container class="boxed">
                <header>Herkomst</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Bron" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        label="Bron"
                        persistent-placeholder
                        :items="sourceTypes"
                        item-text="descr"
                        item-value="key"
                        v-model="selectedData.sourceType"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider name="Source Reference" rules="max:50" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        label="Bron referentie"
                        persistent-placeholder
                        :counter="50"
                        v-model="selectedData.sourceReference"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
            </masonry>
            <details-buttons
              :addMode="isAddingMode"
              :editMode="!isReadOnly"
              :allowSave="!isSaving"
              @edit="setEditMode"
              @edit-tl="setEditTLMode"
              @on-cancel="onCancel"
              @add-new-detail="addNewDetail"
              @on-cancel-add="onCancelAdd()"
              @save-detail="saveDetail"
              @save-new-detail="saveNewDetail"
            />
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>
